.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 50px;
    width: 50px;
    background: transparent;
    border: none;
    padding: 0;
    box-sizing: border-box;
    position: absolute;
    left: 1%;
    top: 1%;
    z-index: 100;
}

.toggle-button:focus {
    outline: none;
}

.toggle-button-line {
    width: 100%;
    height: 5px;
    background: white;
}

.text {
    font-size: 2rem;
    color: white;
    font-weight: bold;
    z-index: 2;
    position: relative;
    left: 3vw;

    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

}

.coltest {
    max-width: 3.5vw; 
    padding-left: 0; 
    padding-right: 0;
    margin-left: .5vw;
    margin-right: .5vw;
    top: 1vh;
    position: absolute;
}

.cont {
    display: flex;
    float: left;
    margin: 1%;
    
}
@media (max-width: 1500px)
{
    .text {
        left: 5vw;
    }
}

@media (max-width: 1400px)
{
    .text {
        left: 5vw;
    }
}

@media (max-width: 1300px)
{
    .text {
        left: 5vw;
    }
}

@media (max-width: 1200px)
{
    .text {
        left: 5vw;
    }
}


@media (max-width: 992px)
{
    .text {
        left: 7vw;
    }

}

@media (max-width: 768px)
{
    
    .text {
        left: 9vw;
    }
}


@media (max-width: 560px)
{
    .text {
        left: 8vw;
        top: 1vw;
        font-size: 1.5rem;
    }

    .toggle-button-line {
        width: 90%;
    }

}

@media (max-width: 450px)
{
    
    .paragraph {
        font-size: 20px;
    }

    .text{
        left: 10vw;
    }
    
}

@media (max-width: 300px)
{

    .text{
        left: 13vw;
    }
    
}