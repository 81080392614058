.nav-link.active {
    color: black;
    background-color: white;
    font-weight: bold;
    font-size: 30px;
}

.nav-link {
    font-size: 30px;
    color: white ;
}

.center {
    padding-top: 3%;
    margin: 0 auto;
    width: 23%;
}

.centerServices {
    padding-top: 3%;
    margin: 0 auto;
    width: 29%;
}

.pad {
    padding-top: 1%;
}

@media (max-width: 1400px)
{
    .nav-link.active {
        font-size: 20px;
    }

    .nav-link {
        font-size: 20px;
    }

    .center {
        width: 32%;
    }
}

@media (max-width: 1200px)
{
    .nav-link.active {
        font-size: 20px;
    }

    .nav-link {
        font-size: 20px;
    }

    .center {
        width: 35%;
    }

}

@media (max-width: 992px)
{
    
    .nav-link.active {
        font-size: 15px;
    }

    .nav-link {
        font-size: 15px;
    }

    .center {
        width: 35%;
    }

}

@media (max-width: 768px)
{
    .nav-link.active {
        font-size: 15px;
    }

    .nav-link {
        font-size: 15px;
    }

    .center {
        width: 50%;
    }

}

@media (max-width: 560px)
{
    
    .nav-link.active {
        font-size: 15px;
    }

    .nav-link {
        font-size: 15px;
    }

    .center {
        width: 60%;
    }
}

@media (max-width: 450px)
{
    .center {
        width: 85%;
    }
    
}