@font-face {
    font-family: 'Caveat', cursive;
    src: url('https://fonts.googleapis.com/css2?family=Caveat:wght@700');
}

.title {
    font-size: 90px;
    font-family: 'Caveat', cursive;
    color: white;
}

.titlecontainer {
    display: flex;
    justify-content: center;
}

.inforow {
    display: flex;
    justify-content: start;
}

.headshot {
    margin: 0 auto;
    width: 90%;
    
}

.paragraph {
    margin: 2%;
    margin-left: 10%;
    text-indent: 100px;
    font-size: 30px;
    color: white;
}

@media (max-width: 1500px)
{
    .paragraph {
        font-size: 25px;
    }
}

@media (max-width: 1400px)
{
    .paragraph {
        font-size: 23px;
        text-indent: 75px;
    }
}

@media (max-width: 1300px)
{
    .paragraph {
        font-size: 23px;
        text-indent: 75px;
    }
}

@media (max-width: 1200px)
{
    .paragraph {
        font-size: 20px;
        text-indent: 50px;
    }
}


@media (max-width: 992px)
{
    .paragraph {
        font-size: 20px;
        text-indent: 50px;
    }

    .title {
        font-size: 80px;
    }

}

@media (max-width: 768px)
{
    
    .paragraph {
        font-size: 20px;
        text-indent: 50px;
    }

    .title {
        font-size: 70px;
    }
}


@media (max-width: 560px)
{
    .paragraph {
        font-size: 20px;
    }

}

@media (max-width: 450px)
{
    
    .paragraph {
        font-size: 20px;
    }
    
}