body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: radial-gradient(rgb(133,161,174),rgb(117,125,154));
  /* Choice 1: radial-gradient(rgb(133,161,174),rgb(117,125,154));
     Choice 2: linear-gradient(rgba(235,51,73,0.5),rgb(117,125,154));
     Choice 3: radial-gradient(rgba(235,51,73,0.5),rgb(224,92,67));
  */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: white;
}

a:hover {
  color: white;
  text-decoration-color: red;
}

.tour {
  margin-left: 25%;
  margin-right: 0;
}

.embed-responsive-4by3 {
  height: 50vh;
  width: 50vw;
}

@media (max-width: 900px) 
{
  .embed-responsive-4by3 {
    width: 75vw;
  }

  .tour {
    margin-left: 10%;
  }

}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) {

    .embed-responsive-4by3 {
      width: 75vw;
    }

    .tour {
      margin-left: 12%;
    }

}