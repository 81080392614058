.side-drawer {
    height: 100%;
    z-index: 200;
    background:rgba(28,34,41,1);
    box-shadow: 2px 0px 5px rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    width: 250px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}

.side-drawer.open {
    transform: translateX(0);

}

.side-drawer ul {
    height: 80%;
    list-style: none;
    display: flex ;
    flex-direction: column;
    justify-content: center;
    padding-left: 0;
    margin: 5%;
}

.side-drawer li {
    margin: 1rem 0;
    margin-left: 0;
    padding-left: 0;
    text-align: center;
}

.side-drawer a{
    color: red;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    font-size: 1.2rem;
}

.side-drawer a:hover 
.side-drawer a:active {
    color:orange;
}

.logo {
    width: 105%;
    left: 50%;
}