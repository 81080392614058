
.bgimg {
    background-image: url('../../images/Interior/real-estate-photo-picture-shot-of-house-interior11.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    
}

.leavemsg {
    font-size: 60px;
    color: white;
    font-family: 'Caveat', cursive;
}

.contactDiv {
    position: absolute;
    transform: translate(+50%, +10%);
    min-width: 50vw;
    min-height: 50vh;
    background-color: rgba(73, 73, 73, 0.5);
    text-align: center;
}

.contactlogo {
    position: absolute;
    
    top: 1%;
    right: 1%;
    z-index: 1;
    width: 20%;
}

.short-form-entries {
    width: 75%;
    background-color: rgba(255, 255, 255, .7);
    padding-top: 2%;
}

.button-form-control {
    margin: auto;
}

@media (max-width: 560px)
{
    .contactDiv {
        min-width: 90vw;
        transform: translate(+5%, +15%);
    }

    .leavemsg {
        font-size: 40px;
    }
}

@media only screen 
  and (min-width: 768px) 
  and (max-height: 1024px) 
  and (orientation: portrait){

    .contactDiv {
        min-width: 90vw;
        transform: translate(+5%, +20%);
    }

}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait){

    .contactDiv {
        min-width: 90vw;
        transform: translate(+5%, +40%);
    }

}



@media (max-width: 450px)
{
    .contactDiv {
        min-width: 90vw;
        transform: translate(+5%, +12%);
    }
}

@media (max-width: 380px)
{
    .contactDiv {
        min-width: 90vw;
        transform: translate(+5%, +10%);
    }

    
}

@media(max-width: 370px)
{
    .contactDiv {
        min-width: 70vw;
        transform: translate(+20%, +10%);
    }

    .leavemsg {
        font-size: 30px;
    }
   
}

@media(max-width: 320px)
{
    .contactDiv {
        min-width: 85vw;
        transform: translate(+8%, +0%);
    }

    .short-form-entries {
        width: 70%;
        background-color: rgba(255, 255, 255, .7);
        padding-top: 2%;
    }
   
}

@media(max-width: 280px) 
{
    .contactDiv {
        transform: translate(+8%, +10%);
    }
}