.footerdiv {
    margin-top: 5%;
    background-color: rgb(34, 78, 107);
    min-width: 50%;
    min-height: 25vh;
    padding: 0;
}

.logo {
    margin-top: 12%;
    width: 100% !important;
    margin-top: 20% !important;
}

.vet {
    width: 75% !important;
}

.zillow {
    width: 60% !important;
}

a:link {
    color: white;
}

.contactinfo {
    float: left;
    padding-top: 18%;
    margin-top: 0%;
    font-size: 2.5vw;
    font-weight: bold;
    font-style: italic;
    color: white;
}

.zillowcol {
    max-width: 12%;
    display: block;
    margin: auto;
}

.contactcol {
    padding: 0;
    padding-top: 1%;
}

.socials {
    width: 45%;
    float: right;
    margin-top: 15%;
    margin-right: 5%;
}

.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
}

.col {
    flex: 15%;
    max-width: 18%;
    padding: 0 4px;
    margin-bottom: 2vh;
    text-align: center;
}

.col img {
    margin-top: 2vh;
    vertical-align: middle;
    width: 75%;
}

@media (max-width: 800px){
    .col {
        flex: 30%;
        max-width: 100%;
    }

    .col img{ 
        width: 75%;
    }

    .col header{
        font-size: 4vw;
    }

    .vet {
        width: 75%;
    }

    .zillow {
        width: 75%;
    }

}

@media (max-width: 1300px)
{
    .contactinfo {
        font-size: 23px;
    }

    .footerdiv {
        min-height: 20vh;
    }

    .socials {
        margin-top: 9%;
    }

    .logo {
        margin-top: 10%;
    }

}

@media (max-width: 1200px)
{
    .contactinfo {
        font-size: 20px;
    }

    .footerdiv {
        min-height: 15vh;
    }
}


@media (max-width: 992px)
{
    .contact-info {
        font-size: 15px;
    }

    .vet {
        width: 90%;
    }

    .zillowcol {
        max-width: 15%;
    }

}

@media (max-width: 768px)
{
    
    .contactinfo {
        font-size: 12px;
    }

    .footerdiv {
        min-height: 12vh;
    }

    .zillowcol {
        max-width: 15%;
    }
}


@media (max-width: 560px)
{
    .contactinfo {
        font-size: 10px;
    }

    .footerdiv {
        min-height: 10vh;
    }

}

@media (max-width: 450px)
{
    .logo {
        min-width: 200%;
        margin-top: 30%;
        transform: translateX(-12%)
    }

    .vet {
        width: 100%;
        margin-top: 25%;
        margin-left: 20%;
    }

    .socials {
        width: 60%;
        padding-top: 10%;
    }

    .contactinfo {
        font-size: 13px;
    }

    .contactcol {
        padding-right: 0 !important;
    }

    .zillowcol {
        max-width: 22%;
        float: left;
    }

    .zillow { 
        margin: 0;
    }
    
}