.bg {
    
    min-height: 100vh;
}

.title {
    font-size: 6em;
    font-weight: bold;
    font-style: italic;
    color: white;
    text-align: center;
    font-family: 'Caveat', cursive;
    
}

.imageLeft {
    margin: 2%;
    width: 95%;
    float: right;
    box-shadow: 5px 5px 40px black;
}

.imageRight {
    margin: 2%;
    width: 95%;
    box-shadow: 5px 5px 40px black;
}

.tourDiv {
    text-align: center;
    width: auto;
    height: auto;
}

.tabs {
    padding-top: 1%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    align-content: center;
    width: auto;
}

.logo {
    position: absolute;
    top: 1%;
    right: 1%;
    z-index: 1;
    width: 20%;
    max-width: 250px;
}

.zillow {
    width: 15vh;
}

.tourCol {
    text-align: center;
}

.zillowText {
    text-align: center;
    font-size: 30px;
    color: white;
    text-decoration-color: red;
}

.zillowProfile {
    font-size: 35px;
}

.zillowTitle {
    padding-top: 50px;
}

.playerwrapperleft {
    position: relative;
    padding-top: 56.25%;
}

.playerwrapperright {
    position: relative;
    padding-top: 56.25%;
}

.reactplayer {
    position: absolute;
    top: 0;
    left: 0;
}

@media (max-width: 768px)
{
    .title {
        padding-top: 5%;
    }

}

.portfolioContainer {
    margin: 0 auto;
}

.carousel {
    
    justify-content: center;
    align-items: center;
    text-align: center;
    
}

.desc {
    text-align: center;
    
}

.carousel img {
    min-width: 50%;
    max-width: 75%;
}

.row {
    
}

.headers {
    font-size: 60px;
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
    color: white;

    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-decoration-color: white;
}

.pricing {
    font-size: 40px;
    font-weight: bold;
    font-style: italic;
    color: rgb(127, 25, 23);

    -webkit-text-fill-color: rgb(255, 204, 203);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color:black;
}

.text {
    font-size: 25px;
    
    color: white;
}

@media (max-width: 800px) {
    .col {
        flex: 25%;
        max-width: 25%;
    }

    .title {
        font-size: 4.5em;
    }
}
