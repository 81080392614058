.imageLeft {
    margin: 2%;
    width: 95%;
    float: right;
    box-shadow: 5px 5px 40px black;
}

.imageRight {
    margin: 2%;
    width: 95%;
    box-shadow: 5px 5px 40px black;
}

.viewall {
    text-align: center;
    font-size: 30px;
    color: white;
    text-decoration-color: red;
}

.title {
    font-size: 80px;
    color: white;
    padding-top: 3%;
    text-align: center;
    font-family: 'Caveat', cursive;

}

@-webkit-keyframes slideIn {

    0% {
        opacity: 0;
        transform: translateX(-900px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@-moz-keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-900px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-900px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@media (max-width: 730px) {
    .title {
        font-size: 70px;
    }
}

@media (max-width: 560px)
{

    .title {
        font-size: 40px;
    }

}